export default {
  app: {
    name: "FieldOffice Console",
    brand: "fieldoffice",
    brandName: "FieldOffice"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://3ja3bytwvj.execute-api.us-east-1.amazonaws.com/prod"
  },
  proxyAPI: {
    NAME: "ProxyAPI",
    PATH: "/apiproxy",
  },
  graphql: {
    NAME: "graphql",
    PATH: "/graphql",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_e27i0HVLS",
    APP_CLIENT_ID: "28253iitjp9qllkshi9b0f83kk",
    IDENTITY_POOL_ID: "us-east-1:b8d018cd-8118-41e4-ba5b-876a8e257f60"
  }
};